<template>
  <div class="loginContent">
    <div v-if="active === 1" class="pwdlogin">
      <div class="contentTab">
        <span class="text">账号密码登录</span>
      </div>
      <div class="content">
        <div class="srk">
          <div class="phone">
            <img src="../assets/images/uname.png" />
            <input v-model="account" type="text" placeholder="请输入账号"></input>
          </div>
        </div>
        <div class="srk">
          <div class="phone">
            <img src="../assets/images/pwd.png" />
            <input v-model="password" type="password" placeholder="请输入密码" />
          </div>
        </div>
        <div class="btn" @click="accountLogin">立即登录</div>
      </div>
    </div>
    
    <div v-if="active === 2" class="zzh">
      <div class="toptitle">
        <span class="text">选择子账号</span>
      </div>
      <div class="zzhcontent">
        <ul class="zzhList">
          <li class="zzhItem" v-for="(item, index) in user_list" :key="index">
            <div class="left">
              <img src="../assets/images/loginIcon.png" alt="" class="img"/>
              <p>{{ item.uname }}</p>
            </div>
            <van-radio-group v-model="radio">
              <van-radio :name="index + 1" checked-color="#FFA624" icon-size="16px"></van-radio>
            </van-radio-group>
          </li>
        </ul>
        <div class="btn" @click="zzhLogin">登录</div>
      </div>

      <!-- <div class="btn">退出</div> -->
    </div>
  </div>
</template>

<script>
import apiFun from "../servers/apiFun.js";
import comTool from "../assets/js/until";
export default {
  components: {},
  data() {
    return {
      active: 1,
      number: 0,
      code: "",
      phone: "",
      account: "",
      password: "",
      radio: 1,
      name: "",
      list: [],
      user_list: [],
    };
  },
  methods: {
    
    //选择子账号
    zzhLogin() {
      console.log(this.radio);
      let data = {
        activity_id: $cookies.get("activity_id"),
        uname: this.user_list[this.radio - 1].uname,
        mobile: this.account,
      };
      apiFun.ChoiceAccountLogin(data).then((res) => {
        console.log(res.data);
        if (res.code == 200) {
          $cookies.set("mobile", res.data.mobile, "7d");
          $cookies.set("muname", res.data.uname, "7d");
          $cookies.set("uname", res.data.son_account[0].uname, "7d");
          $cookies.set("num", res.data.count, "7d");
          $cookies.set("user_token", res.data.user_token, "7d");
          this.$emit("getUserList", res.data.son_account);
          this.active = 0;
        } else {
          this.$toast.fail(res.msg);
        }
        this.isLogin = 1;
        this.$emit("isLogin", this.isLogin);
        this.$emit("getNum", $cookies.get("num"));
      });
    },
    //账号密码登录
    accountLogin() {
      if (this.account.length > 0 && this.password.length > 0) {
        let data = {
          activity_id: $cookies.get("activity_id"),
          uname: this.account,
          pwd: comTool.getMd5(this.password),
        };
        apiFun.Login(data).then((res) => {
          if (res.code == 200) {
            if (res.data.userInfo) {
              // this.user_list = JSON.parse(JSON.stringify(res.data.userInfo));
              this.list = res.data.userInfo;
              for (const i in this.list) {
                this.$set(this.user_list, i, this.list[i])
              }
              this.active = 2;
            } else {
              this.active = 0;
              this.isLogin = 1;
              $cookies.set("muname", res.data.uname, "7d");
              $cookies.set("uname", res.data.son_account[0].uname, "7d");
              $cookies.set("num", res.data.count, "7d");
              this.$emit("getUserList", res.data.son_account);
              this.$emit("isLogin", this.isLogin);
              this.$emit("getNum", $cookies.get("num"));
            }
          } else {
            this.$toast.fail(res.msg);
          }
        });
      } else {
        this.$toast.fail("请输入正确的账号密码！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loginContent {
  .pwdlogin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../assets/images/login.png");
    background-size: 1021px 1476px;
    width: 1021px;
    height: 1476px;
    .contentTab {
      position: relative;
      margin: 0 auto;
      top: 620px;
      width: 860px;
      height: 220px;
      line-height: 220px;
      background: #f8f8f8;
      border-radius: 30px;
      .text {
        position: absolute;
        top: -30px;
        left: 280px;
        font-weight: normal;
        font-size: 45px;
        color: #eb9334;
        text-shadow: 0 0 0;
      }
    }
    .content {
      position: relative;
      top: 569px;
      left: 79px;
      background: #f8f8f8;
      background-size: 860px 353px;
      width: 860px;
      height: 353px;
      .btn {
        width: 729px;
        height: 130px;
        background: #ffa624;
        border-radius: 6vw;
        font-size: 4vw;
        color: #fff;
        text-align: center;
        line-height: 130px;
        margin: 105px auto;
      }
      .srk {
        width: 730px;
        height: 124px;
        margin: 0 auto;
        margin-bottom: 5vw;
        .phone {
          width: 730px;
          height: 124px;
          display: flex;
          background: #FDF6EE;
          align-items: center;
          p {
            width: 20vw;
            text-align: center;
            color: #ffa624;
            font-size: 4vw;
          }
        }
        img {
          width: 30px;
          height: 31px;
          margin-left: 4.9333vw;
          margin-right: 2.8vw;
        }
        input {
          width: 530px;
          height: 124px;
          background: #fdf6ee;
          border-radius: 10px;
          font-size: 4vw;
          color: #666666;
          outline-color: invert;
          outline-style: none;
          outline-width: 0px;
          border-style: none;
        }
      }
    } 
  }

  .zzh {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../assets/images/login.png");
    background-size: 1021px 1476px;
    width: 1021px;
    height: 1476px;
    .toptitle {
      position: relative;
      margin: 0 auto;
      top: 520px;
      width: 860px;
      height: 220px;
      line-height: 220px;
      background: #f8f8f8;
      border-radius: 30px;
      .text {
        position: absolute;
        top: -30px;
        left: 280px;
        font-weight: normal;
        font-size: 45px;
        color: #eb9334;
        text-shadow: 0 0 0;
      }
    }
    .zzhcontent {
      position: relative;
      top: 309px;
      left: 79px;
      background: #f8f8f8;
      background-size: 860px 353px;
      width: 860px;
      height: 503px;
      .zzhList {
        width: 860px;
        margin: 0 auto;
        margin-top: 10vw;
        height: 503px;
        overflow-y: scroll;
        .zzhItem {
          width: 775px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #eaeaea;
          margin-top: 1vw;
          padding-bottom: 2vw;
          .left {
            display: flex;
            align-items: center;
            .img {
              width: 87px;
              height: 87px;
              margin-right: 3vw;
              border-radius: 50%;
            }
          }
        }
        :last-child {
          border-bottom: none;
        }
      }
    }
    .btn {
      position: relative;
      width: 730px;
      height: 130px;
      line-height: 130px;
      background: #ffa624;
      color: #fff;
      border-radius: 6vw;
      margin: 95px auto;
      font-size: 4vw;
      text-align: center;
    }
    .btn1 {
      margin-top: 8vw;
    }
  }
  /deep/ .van-tabs__line {
    width: 20vw;
  }
  /deep/ .van-tab__text {
    color: #666666;
  }
}
</style>

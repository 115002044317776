<template>
  <div class="dzp-lottery">
    <div class="content1" :style="{ height: '245vw' }">
      <!-- <div
        class="text1"
      >
        充值:{{ money }}获得次数
      </div> -->
      <div
        class="text3"
        @click="
          showRecord();
          getPrizeRecord();
        "
      >
        中奖记录
      </div>
      <!-- 中奖记录 -->
      <div class="component_popup" v-if="isshowRecord">
        <div class="popup_mask" @click="showRecord"></div>
        <div class="popup_content">
          <div class="content_box">
            <ol class="prize_list">
              <li
                class="prize_record"
                v-for="(item, index) in prizeRecord"
                :key="item.add_time"
              >
                {{ item.prize_name }} {{ item.add_time }}
              </li>
            </ol>
          </div>
          <div class="content_close" @click="showRecord"></div>
        </div>
      </div>
      <!-- 得奖弹窗 -->
      <div class="component_popup" v-if="isshowPrize">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <div class="content_box2" @click="showPrize">
            <div class="prize_photo">
              <img :src="imgUrl" width="284px" height="215px" />
            </div>
            <div class="prize_name">{{ prize_name }}</div>
          </div>
          <div class="content_close2" @click="showPrize"></div>
        </div>
      </div>
      <!-- 无奖弹窗 -->
      <div class="component_popup" v-if="isshowNoPrize">
        <div class="popup_mask" @click="showNoPrize"></div>
        <div class="popup_content">
          <div class="content_box3">
            <div class="noprize_btn" @click="showNoPrize"></div>
            <div class="noprize_text">很遗憾，大奖与您擦肩而过</div>
          </div>
        </div>
      </div>
      <LuckyWheel
        ref="myLucky"
        width="85vw"
        height="85vw"
        :blocks="blocks"
        :prizes="prizes"
        :buttons="buttons"
        :defaultConfig="defaultConfig"
        @start="startCallBack"
        @end="endCallBack"
        class="dzp"
      />
      <!-- 活动规则 -->
      <p class="text">今日剩余抽奖机会：{{ num }}次</p>
      <div class="board">
        <div class="text2" v-html="rule"></div>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "../components/index/titleBar.vue";
import { LuckyWheel } from "@lucky-canvas/vue";
import apiFun from "../servers/apiFun";
import comTool from "../assets/js/until";
import login from "./login";

export default {
  components: {
    titlebar,
    LuckyWheel,
    login,
  },
  props: {
    click: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      data: {},
      isshowRecord: false,
      isshowPrize: false,
      isshowNoPrize: false,
      isshowSwitch: false,
      isshowFireFlower: false,
      imgUrl: "",
      prizeRecord: "",
      prize_name: "",
      prize_url: "",
      rule: "",
      activity_id: "",
      draw_type_id: "",
      client: "",
      itemTitle: "筛选",
      activity_name: "",
      radio: 1,
      userName: "",
      gameName: "",
      user_game_list: [],
      num: "0",
      money: 500,
      blocks: [
        { padding: "10px" },
        {
          padding: "10px",
          imgs: [
            {
              src: require("../assets/images/dzpzp.png"),
              width: "100%",
              height: "100%",
              rotate: true,
            },
          ],
        },
      ],
      prizes: [
        {
          imgs: [
            {
              src: require("../assets/images/shouji.png"),
              width: "45%",
              top: "40%",
            },
          ],
          background: "",
        },
        {
          imgs: [
            {
              src: require("../assets/images/haiyangyingji.png"),
              width: "35%",
              top: "30%",
            },
          ],
          background: "",
        },
        {
          imgs: [
            {
              src: require("../assets/images/luola.png"),
              width: "35%",
              top: "30%",
            },
          ],
          background: "",
        },
        {
          imgs: [
            {
              src: require("../assets/images/lapian.png"),
              width: "35%",
              top: "30%",
            },
          ],
          background: "",
        },
        {
          imgs: [
            {
              src: require("../assets/images/coupon.png"),
              width: "30%",
              top: "30%",
            },
          ],
          background: "",
        },
        {
          imgs: [
            {
              src: require("../assets/images/libao.png"),
              width: "30%",
              top: "30%",
            },
          ],
          background: "",
        },
        // {
        //   fonts: [
        //     {
        //       text: "谢谢\n参与",
        //       top: "33%",
        //       fontColor: "#FFFFFF",
        //       fontSize: "24px",
        //       wordWrap: false,
        //     },
        //   ],
        //   background: "",
        // },
      ],
      defaultConfig: {},
      buttons: [
        {
          radius: "50%",
          imgs: [
            {
              src: require("../assets/images/dzpan.png"),
              width: "68%",
              top: "-83%",
            },
          ],
        },
      ],
      index: Number,
      timer: null,
      second: 0,
    };
  },
  created() {
    this.index = Math.floor(Math.random() * 8) + 1;
    // this.mmh = document.documentElement.clientHeight + "px";
    if (this.$route.query.access_token) {
      this.client = "android";
      this.sdkInit();
    } else {
      this.client = "h5";
      this.init();
    }
  },
  mounted() {
    console.log(LuckyWheel);
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))   //监听页面刷新触发事件
  },
  destroyed () {  //进行监听销毁
    window.removeEventListener('beforeunload', e =>this.beforeunloadHandler(e))
  },
  methods: {
    //正常初始化
    init() {
      let data = {
        activity_id: this.$route.query.activity_id ? this.$route.query.activity_id : "1",
      };
      apiFun.Init(data).then((res) => {
        if (res.code == 200) {
          this.rule = res.data.activity.content;
          $cookies.set("activity_id", res.data.activity.activity_id);
          $cookies.set("draw_type_id", res.data.activity.draw_type_id);
          this.activity_id = res.data.activity.activity_id;
          this.draw_type_id = res.data.activity.draw_type_id;
          this.activity_name = res.data.activity.activity_name;
          if (res.data.activity.draw_type_id == "2") {
            this.$router.push({
              path: "/lotteryjgg",
              query: { activity_id: $cookies.get("activity_id") },
            });
          } else if (res.data.activity.draw_type_id == "3") {
            this.$router.push({
              path: "/lotteryndj",
              query: { activity_id: $cookies.get("activity_id") },
            });
          }
          this.sdkDrawTimes();
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //sdk初始化
    sdkInit() {
      $cookies.set("pid", this.$route.query.pid, "7d");
      $cookies.set("gid", this.$route.query.gid, "7d");
      $cookies.set("s_access_token", this.$route.query.access_token, "7d");
      $cookies.set("uname", this.$route.query.uname, "7d");
      this.init();
    },
    //sdk获取抽奖次数
    sdkDrawTimes() {
      let data = {
        pid: $cookies.get("pid"),
        gid: $cookies.get("gid"),
        access_token: $cookies.get("s_access_token"),
        uname: $cookies.get("uname"),
        activity_id: this.activity_id,
        client: this.client,
      };
      apiFun.SdkDrawTimes(data).then((res) => {
        if (res.code == 200) {
          this.num = res.data.num;
          this.money = res.data.next_amt;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //转盘抽奖
    startCallBack() {
      if (
        this.num > 0 &&
        this.isshowRecord === false &&
        this.isshowPrize === false
      ) {
        this.num -= 1;
        // 开始游戏
        this.$refs.myLucky.play();
        // 假设接口的请求速度是5s
        this.getData();
        setTimeout(() => {
          // 5s后拿到后端返回的中奖索引
          const prize_id = $cookies.get("id");
          // 然后停止游戏 (缓慢停止)
          this.$refs.myLucky.stop(prize_id);
        }, 5000);
      } else if (this.num == 0) {
        this.$toast.fail("剩余抽奖次数为 0 ，不能再抽奖了哦");
      }
    },
    endCallBack(prize) {
      this.isshowPrize = true;
    },
    //抽奖接口
    getData() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        activity_id: this.activity_id,
        draw_type_id: this.draw_type_id,
        uname: $cookies.get("uname"),
        access_token: $cookies.get("s_access_token"),
        client: this.client,
      };
      apiFun.Start(data).then((res) => {
        if (res.code == 200) {
          let id = parseInt(res.data.id);
          if (id > 6) {
            id = (id - 1) % 6;
          }
          $cookies.set("id", id);
          this.imgUrl = res.data.prize_url;
          this.prize_name = res.data.prize_name;
          this.num = res.data.draw_times;
          // if (id == 5) {
          //   this.prize_name = "";
          // }
        } else {
          this.$toast.fail(res.msg);
          this.prize_name = "";
          $cookies.set("id", 5);
        }
      });
    },
    //抽奖记录接口
    getPrizeRecord() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        access_token: $cookies.get("s_access_token"),
        uname: $cookies.get("uname"),
        activity_id: this.activity_id,
        client: this.client,
      };
      apiFun.PrizeRecord(data).then((res) => {
        if (res.code == 200) {
          this.prizeRecord = res.data;
        }
      });
    },
    //sdk交互方法
    getSDKToken() {
      let s_access_token = window.liuyiAndroidObject.dzpGetLoginToken();
      $cookies.set("s_access_token", s_access_token, "7d");
    },
    //控制方法
    showRecord() {
      this.isshowRecord = !this.isshowRecord;
    },
    showPrize() {
      this.isshowPrize = !this.isshowPrize;
    },
    showNoPrize() {
      this.isshowNoPrize = !this.isshowNoPrize;
    },
    showSwitch() {
      this.isshowSwitch = !this.isshowSwitch;
    },
    getNum(data) {
      this.num = data;
      console.log("num=", this.num);
    },
    /** 在刷新和关闭之前询问 **/
    beforeunloadHandler(e) {
        $cookies.remove("access_token");
        $cookies.remove("s_access_token");
        $cookies.remove("user_token");
        $cookies.remove("uname");
        $cookies.remove("uid");
        $cookies.remove("pid");
        $cookies.remove("gid");
        $cookies.remove("mobile");
        $cookies.remove("realName");
        $cookies.remove("idNumber");
        $cookies.remove("num");
    },
    out() {
      $cookies.remove("access_token");
      $cookies.remove("s_access_token");
      $cookies.remove("user_token");
      $cookies.remove("uname");
      $cookies.remove("uid");
      $cookies.remove("pid");
      $cookies.remove("gid");
      $cookies.remove("mobile");
      $cookies.remove("realName");
      $cookies.remove("idNumber");
      $cookies.remove("num");
      this.$router.go(0);
    },
  },
};
</script>

<style>
.van-toast {
  width: 800px;
  min-height: 200px;
  line-height: 50px;
}
</style>
<style lang="less">
.dzp-lottery {
  overflow: hidden;
}
.content1 {
  background-image: url("../assets/images/zpbanner.png");
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  image-rendering: -webkit-optimize-contrast;
  .dzp {
    position: relative;
    top: 55vw;
    left: 5vw;
  }
  .text {
    position: relative;
    top: 54.5vw;
    left: 28vw;
    color: #ffffff;
    font-size: 4vw;
    font-weight: 800;
    letter-spacing: 0.1vw;
    font-family: Source Han Sans CN;
    text-shadow: 0 0.7vw 0.7vw rgba(255, 144, 46, 0.75);
  }
  .text1 {
    position: absolute;
    font-family: Source Han Sans CN;
    color: #6098d1;
    font-size: 48px;
    font-weight: bold;
    width: 400px;
    top: 630px;
    left: 52px;
  }
  .text3 {
    position: absolute;
    font-family: Source Han Sans CN;
    color: #6098d1;
    font-size: 54px;
    font-weight: bold;
    width: 220px;
    top: 750px;
    left: 980px;
    z-index: 2;
  }
  .board {
    position: relative;
    background-image: url("../assets/images/dzpboard.png");
    background-size: 93vw 69vw;
    width: 93vw;
    height: 69vw;
    // line-height: 7vw;
    top: 72vw;
    margin: auto;
    .text2 {
      position: relative;
      width: 86vw;
      height: 49vw;
      // font-family: Source Han Sans CN;
      color: #dc1912;
      top: 15vw;
      left: 5vw;
      font-size: 3.5vw;
      line-height: 6vw;
      font-weight: 550;
      text-align: left;
      white-space: pre-wrap;
      word-break: break-all;
      word-wrap: break-word;
      overflow-y: scroll;
    }
  }
}
.component_popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
}
.popup_mask {
  background-color: #000000;
  opacity: 0.79;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.popup_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content_box {
  background-image: url("../assets/images/prizerecord.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_list {
    position: relative;
    width: 660px;
    top: 540px;
    margin: 0 auto;
    height: 553px;
    overflow-x: scroll;
    overflow-y: scroll;
    list-style: decimal inside;
    .prize_record {
      top: 780px;
      left: 40%;
      margin: 60px auto;
      font-size: 46px;
      font-family: PingFang SC;
      color: #ffffff;
    }
  }
}
.content_close {
  position: absolute;
  width: 20%;
  height: 150px;
  margin: 0 auto;
  top: 90%;
  left: 40%;
}
.content_box2 {
  background-image: url("../assets/images/prize.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_name {
    position: relative;
    top: 100px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 70px;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
  .prize_photo {
    position: relative;
    top: 17vw;
    width: 78vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
}
.content_close2 {
  position: absolute;
  width: 20%;
  height: 150px;
  margin: 0 auto;
  top: 90%;
  left: 40%;
}
.logintext {
  position: absolute;
  top: 199px;
  left: 40px;
  font-size: 46px;
  font-family: PingFang SC;
  color: #ffffff;
}
.content_box3 {
  background-image: url("../assets/images/noprize.png");
  background-size: 869px 1105px;
  width: 869px;
  height: 1105px;
  text-align: center;
  .noprize_text {
    position: relative;
    top: 529px;
    font-size: 59px;
    font-weight: bold;
    font-family: Adobe Heiti Std;
    color: #ffffff;
  }
  .noprize_btn {
    background-image: url("../assets/images/onemore.png");
    background-size: 601px 148px;
    width: 601px;
    height: 148px;
    position: relative;
    top: 867px;
    left: 15%;
  }
}

.content_box4 {
  background-image: url("../assets/images/getPrize.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
}
  
.switchList {
  width: 860px;
  margin: 0 auto;
  height: 503px;
  overflow-y: scroll;
  .switchItem {
    width: 67vw;
    height: 7vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    margin-top: 1vw;
    font-size: 4vw;
  }
  :last-child {
    border-bottom: none;
  }
}

.popper-style {
  border-radius: 4vw;
}

</style>
